'use client'

import classnames from 'classnames'
import styles from './OrderDropdown.module.scss'
import Button from '@/components/Button/Button'
import useI18n from '@/hooks/use-i18n'
import { getDomainNameFromUrlString, getServiceLinksByService } from '@/data/sanity/utils'
import { SERVICE_TYPES } from '@/data'
import Link from '@/components/Link/Link'
import { useRef, useState } from 'react'
import useCurrentPage from '@/hooks/use-current-page'

type OrderDropdownCustomButtonItems = {
  label: string
  callback: () => void
}

type OrderDropdownProps = {
  className?: string
  serviceLinks?: SanityLocationServiceLinkType[]
  customButtonItems?: OrderDropdownCustomButtonItems[]
  dropdownPosition?: 'bottomLeft' | 'bottomRight' | 'topRight' | 'topLeft'
  label?: string
  primary?: boolean
  isReserve?: boolean
  onLinkClick?: () => void
  slug?: string
  isSoftOpening?: boolean
}

const OrderDropdown = ({
  className,
  serviceLinks,
  customButtonItems,
  dropdownPosition = 'bottomLeft',
  label,
  primary,
  isReserve,
  isSoftOpening,
  onLinkClick,
  slug,
}: OrderDropdownProps) => {
  const { i18n } = useI18n()
  const [isFocused, setIsFocused] = useState(false)
  const serviceLinksByService = getServiceLinksByService(serviceLinks || [])
  const isSingleLink = serviceLinks?.length === 1 || customButtonItems?.length === 1
  const blurTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const hasLinkClicked = useRef(false)
  const { currentSlug } = useCurrentPage()

  const linkValue =
    serviceLinks?.length === 1
      ? {
          link: serviceLinks[0].url,
          linkType: 'external',
        }
      : null

  let buttonLabel = label || i18n('order')
  if (isReserve) {
    buttonLabel = i18n('reserve')
  }

  const handleClick = () => {
    if (hasLinkClicked.current) return
    if (isSingleLink) return
    hasLinkClicked.current = true
    setTimeout(() => {
      hasLinkClicked.current = false
    }, 50)
    setIsFocused(prev => !prev)
  }

  const handleLinkClick = () => {
    if (onLinkClick) onLinkClick()
    setIsFocused(false)
  }

  const handleLinkFocus = () => {
    if (blurTimeoutRef.current) {
      clearTimeout(blurTimeoutRef.current)
    }
    setIsFocused(true)
  }

  if (!serviceLinks?.length && !customButtonItems?.length) return null

  const isManhattan = slug === 'new-york' || currentSlug === 'new-york'

  return (
    <div
      className={classnames(styles.OrderDropdown, className, { [styles.isFocused]: isFocused })}
      data-dropdown-position={dropdownPosition}
    >
      <Button
        onClick={handleClick}
        onBlur={() => {
          if (blurTimeoutRef.current) {
            clearTimeout(blurTimeoutRef.current)
          }
          blurTimeoutRef.current = setTimeout(() => {
            setIsFocused(false)
          }, 100)
        }}
        link={linkValue as SanityLink}
        primary={primary}
        secondary={!primary}
        icon={isSingleLink ? 'caretRight' : 'caretDown'}
        label={buttonLabel}
        className={styles.button}
      />
      {!isSingleLink && (
        <div
          className={styles.dropdown}
          data-lenis-prevent
        >
          <div className={styles.dropdown__inner}>
            {customButtonItems?.map((linkObject, i) => (
              <button
                key={i}
                className={styles.customButton}
                onClick={() => {
                  if (linkObject.callback) linkObject.callback()
                  handleLinkClick()
                }}
                onFocus={handleLinkFocus}
              >
                {linkObject.label}
              </button>
            ))}
            {serviceLinksByService[SERVICE_TYPES.GENERAL_RESERVATION].map((item, i) => {
              return (
                <Link
                  key={i}
                  className={styles.dropdownLink}
                  link={{
                    label: i18n('generalReservation'),
                    linkType: 'external',
                    link: item.url,
                  }}
                  onClick={handleLinkClick}
                  onFocus={handleLinkFocus}
                />
              )
            })}
            {!isSoftOpening && serviceLinksByService[SERVICE_TYPES.DINING_ROOM].map((item, i) => {
              return (
                <Link
                  key={i}
                  className={styles.dropdownLink}
                  link={{
                    label: i18n(isManhattan ? 'diningRoomManhattan' : 'diningRoom'),
                    linkType: 'external',
                    link: item.url,
                  }}
                  onClick={handleLinkClick}
                  onFocus={handleLinkFocus}
                />
              )
            })}
            {!isSoftOpening && serviceLinksByService[SERVICE_TYPES.BAR_COUNTER].map((item, i) => {
              return (
                <Link
                  key={i}
                  className={styles.dropdownLink}
                  link={{
                    label: i18n('barCounter'),
                    linkType: 'external',
                    link: item.url,
                  }}
                  onClick={handleLinkClick}
                  onFocus={handleLinkFocus}
                />
              )
            })}
            {!isSoftOpening && serviceLinksByService[SERVICE_TYPES.PICKUP][0] && (
              <Link
                className={styles.dropdownLink}
                link={{
                  label: i18n('orderPickUp'),
                  linkType: 'external',
                  link: serviceLinksByService[SERVICE_TYPES.PICKUP][0].url,
                }}
                onClick={handleLinkClick}
                onFocus={handleLinkFocus}
              />
            )}
            {!isSoftOpening && serviceLinksByService[SERVICE_TYPES.CATERING][0] && (
              <Link
                className={styles.dropdownLink}
                link={{
                  label: i18n('orderCatering'),
                  linkType: 'external',
                  link: serviceLinksByService[SERVICE_TYPES.CATERING][0].url,
                }}
                onClick={handleLinkClick}
                onFocus={handleLinkFocus}
              />
            )}
            {!isSoftOpening && serviceLinksByService[SERVICE_TYPES.DELIVERY].map((item, i) => {
              return (
                <Link
                  key={i}
                  className={styles.dropdownLink}
                  link={{
                    label: i18n('orderDeliveryService', {
                      service: i18n(item.serviceName || '') || getDomainNameFromUrlString(item.url),
                    }),
                    linkType: 'external',
                    link: item.url,
                  }}
                  onClick={handleLinkClick}
                  onFocus={handleLinkFocus}
                />
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

OrderDropdown.displayName = 'OrderDropdown'

export default OrderDropdown
