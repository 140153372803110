import { getDeviceInfo } from '../device/detection'

export const calculateVhSizing = () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01

  // avoid android bouncing by calculating it this way
  const deviceInfo = getDeviceInfo()
  const isMobileChrome = deviceInfo.device.type === 'mobile' && deviceInfo.browser.chrome
  if (isMobileChrome) {
    vh = document.documentElement.clientHeight * 0.01
  }

  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`)

  // Set the difference below
  document.documentElement.style.setProperty('--navigation-height', `${window.outerHeight - window.innerHeight}px`)
}

export const getCssVar = (variable: string) => {
  return window.getComputedStyle(document.body).getPropertyValue(`--${variable}`)
}


