'use client'

import classnames from 'classnames'
import styles from './RestaurantOpenClosedBlurbNewFormat.module.scss'
import {
  getIfRestaurantIsOpenNewFormat,
  getMergedTimeRangeHoursWithTimeRangeHolidayHours,
  getRestaurantClosingHourNewFormat,
} from '@/utils'
import useI18n from '@/hooks/use-i18n'

type RestaurantOpenClosedBlurbNewFormatProps = {
  timeZone?: string
  weeklyOpeningHours?: WeeklyOpeningHours
  holidayHours: SanityLocationHolidayHours[]
  className?: string
  isComingSoon?: boolean
  isSoftOpening?: boolean
  isTemporarilyClosed?: boolean
}

const RestaurantOpenClosedBlurbNewFormat = ({
  className,
  timeZone,
  weeklyOpeningHours,
  holidayHours,
  isComingSoon,
  isSoftOpening,
  isTemporarilyClosed,
}: RestaurantOpenClosedBlurbNewFormatProps) => {
  const { i18n } = useI18n()

  // Early return for temporarily closed restaurants
  if (isTemporarilyClosed) {
    return (
      <div
        className={classnames(styles.RestaurantOpenClosedBlurb, className)}
        data-is-open={'false'}
        data-is-coming-soon={isComingSoon === true}
      >
        <span className={styles.dot} />
        <span className={styles.text}>Temporarily Closed</span>
      </div>
    )
  }
  // Return null if essential data is missing
  if (!timeZone || !weeklyOpeningHours) return null

  // Merge weekly hours with holiday hours using new function
  const adjustedHours = getMergedTimeRangeHoursWithTimeRangeHolidayHours(weeklyOpeningHours, holidayHours)

  // Check if restaurant is currently open
  const isOpen = getIfRestaurantIsOpenNewFormat(timeZone, adjustedHours)

  // Get today's closing hour
  const currentDate = new Date()
  const closingHour = getRestaurantClosingHourNewFormat(adjustedHours, currentDate)

  // Determine display text based on status
  let text = i18n('locationOpenUntil', { time: closingHour })
  if (!isOpen) {
    text = i18n('locationClosed')
  }
  if (isComingSoon === true || isSoftOpening === true) {
    text = i18n('steamingSoon')
  }

  return (
    <div
      className={classnames(styles.RestaurantOpenClosedBlurb, className)}
      data-is-open={isOpen ? 'true' : 'false'}
      data-is-coming-soon={isComingSoon === true || isSoftOpening === true}
    >
      <span className={styles.dot} />
      <span className={styles.text}>{text}</span>
    </div>
  )
}

RestaurantOpenClosedBlurbNewFormat.displayName = 'RestaurantOpenClosedBlurbNewFormat'

export default RestaurantOpenClosedBlurbNewFormat
