import {
  LANGUAGES,
} from '@/data'
import { getLocationSlug } from '@/utils/urls/slugs'



export const getPagePathBySlug = (slug: string) => {
  let locationKey: string | null = null
  Object.values(LANGUAGES).forEach(location => {
    if (getLocationSlug(location) === slug) {
      locationKey = location
    }
  })

  if (locationKey) {
    return `/${locationKey}`
  }

  return `/${slug}`
}

export const getMenuItemPathBySlug = (slug: string) => {
  return `/menu/${slug}`
}

export const getLocationPathBySlug = (slug: string) => {
  return `/locations/${slug}`
}

export const getBlogPostPathBySlug = (slug: string) => {
  return `/blog/${slug}`
}
