
export const bytesToMb = (bytes: number) => {
  const { size, unit } = formatBytes(bytes)

  let value: number = size

  if (unit === 'KB') {
    value = value / 1024
  }

  return value
}


export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return { unit: 'Bytes', size: 0 }

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return {
    size: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
    unit: sizes[i],
  }
}
