import { DAY_ORDER, TIME_ZONES } from '@/data'
import { formatMilitaryTimeWithoutMinutes } from '@/utils/format/dates'

export const getIfRestaurantIsOpen = (timeZone?: string, hours?: { [key: string]: OpenCloseHours }) => {
  if (!hours || !timeZone) return
  if (typeof hours === 'string') return

  const restaurantDateString = new Date().toLocaleString('en-US', {
    timeZone: TIME_ZONES[timeZone as keyof typeof TIME_ZONES].zone,
  })

  const restaurantDate = new Date(restaurantDateString)
  const restaurantDayName = restaurantDate.toLocaleDateString('en-US', { weekday: 'long' }).toUpperCase()
  const restaurantHoursByDay = hours[restaurantDayName as keyof typeof hours]

  if (!restaurantHoursByDay?.open || !restaurantHoursByDay?.close) return

  const restaurantOpeningDate = new Date(restaurantDateString)
  const restaurantClosingDate = new Date(restaurantDateString)
  const restaurantCurrentDate = new Date(restaurantDateString)

  const openingHoursSplit = restaurantHoursByDay?.open.split(':')
  const openingHoursHour = openingHoursSplit[0]
  const openingHoursMinutes = openingHoursSplit[1]

  const closingHoursSplit = restaurantHoursByDay?.close.split(':')
  const closingHoursHour = closingHoursSplit[0]
  const closingHoursMinutes = closingHoursSplit[1]

  restaurantOpeningDate.setHours(parseInt(openingHoursHour))
  restaurantOpeningDate.setMinutes(parseInt(openingHoursMinutes))
  restaurantOpeningDate.setSeconds(0)

  restaurantClosingDate.setHours(parseInt(closingHoursHour))
  restaurantClosingDate.setMinutes(parseInt(closingHoursMinutes))
  restaurantClosingDate.setSeconds(0)

  const restaurentCurrentTimeMs = restaurantCurrentDate.getTime()
  const restaurantOpeningTimeMs = restaurantOpeningDate.getTime()
  const restaurantClosingTimeMs = restaurantClosingDate.getTime()

  if (restaurentCurrentTimeMs > restaurantOpeningTimeMs && restaurentCurrentTimeMs < restaurantClosingTimeMs) {
    return true
  } else {
    return false
  }
}

export const getRestaurantClosingHour = (timeZone?: string, hours?: { [key: string]: OpenCloseHours }) => {
  if (!hours || !timeZone) return
  if (typeof hours === 'string') return

  const restaurantDateString = new Date().toLocaleString('en-US', {
    timeZone: TIME_ZONES[timeZone as keyof typeof TIME_ZONES].zone,
  })

  const restaurantDate = new Date(restaurantDateString)
  const restaurantDayName = restaurantDate.toLocaleDateString('en-US', { weekday: 'long' }).toUpperCase()
  const restaurantHoursByDay = hours[restaurantDayName as keyof typeof hours]

  if (!restaurantHoursByDay.close) return ''

  return formatMilitaryTimeWithoutMinutes(restaurantHoursByDay?.close)
}


export const getMergedHoursWithHolidayHours = (
  hours: { [key: string]: OpenCloseHours },
  holidayHours?: SanityLocationHolidayHours[],
) => {
  if (!holidayHours) {
    return hours
  }

  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const rangeNumber = 7
  const dayRangeDates: Date[] = []
  const holidayHoursHashedByTime: any = {}
  holidayHours.forEach(item => {
    const holidayDate = new Date(item.date)
    holidayDate.setHours(0, 0, 0, 0)
    holidayHoursHashedByTime[holidayDate.getTime()] = item
  })

  // Fixed for loop - added missing semicolons
  for (let index = rangeNumber * -1; index < rangeNumber; index++) {
    const date = new Date()
    date.setHours(0, 0, 0, 0)
    date.setDate(today.getDate() + index)
    dayRangeDates.push(date)
  }

  const daysByJsName: any = {
    0: 'SUNDAY',
    1: 'MONDAY',
    2: 'TUESDAY',
    3: 'WEDNESDAY',
    4: 'THURSDAY',
    5: 'FRIDAY',
    6: 'SATURDAY',
  }

  const currentDayName = daysByJsName[today.getDay() as any]
  const currentDayIndex = DAY_ORDER.indexOf(currentDayName)

  const datesByDays: any = {}
  DAY_ORDER.forEach((day, i) => {
    const difference = currentDayIndex - i
    const date = dayRangeDates[rangeNumber - 1 - difference]

    let openingClosingHours = hours[day] || { open: null, close: null }
    const holidayHashEntry = holidayHoursHashedByTime[date.getTime()]
    if (holidayHashEntry) {
      openingClosingHours = {
        open: holidayHashEntry.isClosed ? null : holidayHashEntry.openingHour,
        close: holidayHashEntry.isClosed ? null : holidayHashEntry.closingHour,
      }
    }

    datesByDays[day] = openingClosingHours
  })

  return datesByDays
}

// NEW HOURS FORMAT FUNCTIONS

/**
 * Adjusts weekly opening hours based on holiday schedules
 * @param {WeeklyOpeningHours} weeklyOpeningHours - Regular weekly opening hours
 * @param {SanityLocationHolidayHours[]} holidayHours - Array of holiday hours
 * @returns {Object} - Adjusted weekly hours incorporating holiday schedules
 */
export function getMergedTimeRangeHoursWithTimeRangeHolidayHours(weeklyOpeningHours: WeeklyOpeningHours | undefined, holidayHours: SanityLocationHolidayHours[]) {
  // Handle edge cases
  if (!weeklyOpeningHours || !holidayHours || !Array.isArray(holidayHours)) {
    return weeklyOpeningHours || {}
  }

  // Create a copy of the weekly hours to avoid modifying the original
  const adjustedHours = JSON.parse(JSON.stringify(weeklyOpeningHours))

  // Get today's date
  const today = new Date()

  // Array of day names in order from Sunday to Saturday
  const dayNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

  // Calculate the dates for the current week (7 days starting from today)
  const currentWeekDates = []
  for (let i = 0; i < 7; i++) {
    const date = new Date(today)
    date.setDate(today.getDate() + i)

    // Format date as YYYY-MM-DD to match the format in holidayHours
    const formattedDate = date.toISOString().split('T')[0]

    currentWeekDates.push({
      date: formattedDate,
      dayOfWeek: dayNames[date.getDay()],
    })
  }

  // Check for holidays in the current week
  for (const dayInfo of currentWeekDates) {
    // Find matching holiday for this date
    const matchingHoliday = holidayHours.find(holiday => holiday.date === dayInfo.date)

    if (matchingHoliday) {
      const dayOfWeek = dayInfo.dayOfWeek

      if (matchingHoliday.isClosed) {
        // If closed on the holiday, use a special indicator as requested
        adjustedHours[dayOfWeek] = [{ isClosed: true }]
      } else if (matchingHoliday.openingHours && matchingHoliday.openingHours.length > 0) {
        // Use the new openingHours array if available
        adjustedHours[dayOfWeek] = matchingHoliday.openingHours
      } else if (matchingHoliday.openingHour && matchingHoliday.closingHour) {
        // Fall back to legacy fields if needed
        adjustedHours[dayOfWeek] = [{
          open: matchingHoliday.openingHour,
          close: matchingHoliday.closingHour,
        }]
      }
    }
  }

  return adjustedHours
}

/**
 * Determines if a restaurant is currently open based on its timezone and hours
 * @param {string} [timeZone] - The timezone of the restaurant (Atlantic, Eastern, Central, etc.)
 * @param {WeeklyOpeningHours} [weeklyOpeningHours] - Adjusted weekly opening hours
 * @returns {boolean} - True if the restaurant is currently open, false otherwise
 */

export function getIfRestaurantIsOpenNewFormat(timeZone: string | undefined, weeklyOpeningHours: WeeklyOpeningHours) {
  // Return false if no opening hours are provided
  if (!weeklyOpeningHours) {
    return false
  }

  // Get the current time in the restaurant's timezone
  const restaurantDateString = new Date().toLocaleString('en-US', {
    timeZone: TIME_ZONES[timeZone as keyof typeof TIME_ZONES].zone,
  })

  const localTime = new Date(restaurantDateString)

  // Get day of week (0-6, Sunday is 0)
  const dayIndex = localTime.getDay()
  const dayNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
  const dayOfWeek = dayNames[dayIndex]

  // Get current hour and minute
  const currentHour = localTime.getHours()
  const currentMinute = localTime.getMinutes()
  const currentTimeInMinutes = currentHour * 60 + currentMinute

  // Get today's hours
  const todayHours = weeklyOpeningHours[dayOfWeek as keyof WeeklyOpeningHours]

  // Check if restaurant is closed today
  if (!todayHours || todayHours.length === 0 || todayHours[0].isClosed) {
    return false
  }

  // Check if current time falls within any of today's opening hours
  return todayHours.some(timeRange => {
    // Skip if this is not a valid time range
    if (!timeRange.open || !timeRange.close) {
      return false
    }

    // Parse opening time
    const [openHour, openMinute] = timeRange.open.split(':').map(num => parseInt(num))
    const openTimeInMinutes = openHour * 60 + openMinute

    // Parse closing time
    const [closeHour, closeMinute] = timeRange.close.split(':').map(num => parseInt(num))
    const closeTimeInMinutes = closeHour * 60 + closeMinute

    // Check if current time is within this time range
    return currentTimeInMinutes >= openTimeInMinutes && currentTimeInMinutes < closeTimeInMinutes
  })
}

/**
 * Gets the restaurant's closing hour for a given day
 * @param {WeeklyOpeningHours} weeklyOpeningHours - Adjusted weekly opening hours from getMergedTimeRangeHoursWithTimeRangeHolidayHours
 * @param {string|Date} dayOfWeek - Day of week as string ("monday", etc.) or Date object
 * @returns {string|null} - Closing hour in format "HH:MM" or null if closed
 */
export function getRestaurantClosingHourNewFormat(weeklyOpeningHours: WeeklyOpeningHours, dayOfWeek: string | Date) {
  // Return null if no opening hours provided
  if (!weeklyOpeningHours) {
    return null
  }

  // Convert Date object to day string if needed
  let dayString = dayOfWeek
  if (dayOfWeek instanceof Date) {
    const dayNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
    dayString = dayNames[dayOfWeek.getDay()]
  }

  // Get hours for the specified day
  const dayHours = weeklyOpeningHours[dayString as keyof WeeklyOpeningHours]

  // If no hours for this day or explicitly closed, return null
  if (!dayHours || dayHours.length === 0 || dayHours[0].isClosed) {
    return null
  }

  // Find the latest closing time
  let latestClosingTime = null
  let latestClosingMinutes = -1

  dayHours.forEach(timeRange => {
    // Skip if this is not a valid time range
    if (!timeRange.close) {
      return
    }

    // Parse closing time
    const [closeHour, closeMinute] = timeRange.close.split(':').map(num => parseInt(num))
    const closeTimeInMinutes = closeHour * 60 + closeMinute

    // Update latest closing time if this one is later
    if (closeTimeInMinutes > latestClosingMinutes) {
      latestClosingMinutes = closeTimeInMinutes
      latestClosingTime = timeRange.close
    }
  })

  // Return empty string if no valid closing time was found
  if (latestClosingTime === null) {
    return ''
  }

  return formatMilitaryTimeWithoutMinutes(latestClosingTime)
}
