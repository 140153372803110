/**
 * Formats military time (24-hour format) to a 12-hour AM/PM format,
 * omitting the minutes when they are zero (e.g., "10:00 AM" becomes "10 AM").
 *
 * @param {string} hourString - Time string in 24-hour format (e.g., "14:30")
 * @returns {string} Formatted time string in 12-hour format with AM/PM
 * @example
 * // Returns "10 AM"
 * formatMilitaryTimeWithoutMinutes("10:00");
 *
 * @example
 * // Returns "2:30 PM"
 * formatMilitaryTimeWithoutMinutes("14:30");
 */
export const formatMilitaryTimeWithoutMinutes = (hourString: string) => {
  // Handle empty or invalid input
  if (!hourString) return ''

  const hoursSplit = hourString.split(':')
  if (hoursSplit.length === 1) return hourString

  // Parse hours and minutes
  const hour = parseInt(hoursSplit[0]) || 0
  const minutes = parseInt(hoursSplit[1]) || 0

  // Convert to 12-hour format
  let amPmHours = hour % 12
  // Handle 12 o'clock case (noon or midnight)
  amPmHours = amPmHours === 0 ? 12 : amPmHours

  // Determine AM or PM
  const amPm = hour >= 12 ? 'PM' : 'AM'

  // Format minutes
  const amPmMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`

  // Return formatted time, omitting minutes if they're zero
  if (minutes === 0) {
    return `${amPmHours} ${amPm}`
  } else {
    return `${amPmHours}:${amPmMinutes} ${amPm}`
  }
}

export const formatMilitaryTime = (hourString: string) => {
  const hoursSplit = hourString?.split(':')
  if (hoursSplit?.length === 1) return hourString
  const hour = parseInt(hoursSplit[0]) ? parseInt(hoursSplit[0]) : 0
  const minutes = hoursSplit[1] ? parseInt(hoursSplit[1]) : 0

  const amPmHours = hour > 12 ? hour - 12 : hour
  const amPm = hour >= 12 ? 'PM' : 'AM'
  const amPmMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`

  return `${amPmHours}:${amPmMinutes} ${amPm}`
}


export const getFormattedDateString = (dateString: string) => {
  const dateObject = new Date(dateString)
  const date = new Date(dateObject)
  date.setDate(date.getDate() + 1)
  date.setHours(0, 0, 0, 0)
  const day = date.getDate()
  const year = date.getFullYear()
  const month = date.toLocaleString('default', { month: 'long' })

  return `${month} ${day}, ${year}`
}
