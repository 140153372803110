export const proxyAssetCdn = (url: string) => {
  if (
    typeof url === 'string' &&
    process.env.NEXT_PUBLIC_SANITY_ASSET_HOST &&
    process.env.NEXT_PUBLIC_PROXY_ASSET_HOST &&
    process.env.NEXT_PUBLIC_SANITY_PROJECT_ID &&
    url.includes(process.env.NEXT_PUBLIC_SANITY_ASSET_HOST)
  ) {
    const proxyUrl = url
      .replace(process.env.NEXT_PUBLIC_SANITY_ASSET_HOST, process.env.NEXT_PUBLIC_PROXY_ASSET_HOST)
      .replace(`${process.env.NEXT_PUBLIC_SANITY_PROJECT_ID}/`, '')

    return proxyUrl
  }
  return url
}
