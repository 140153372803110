import { imageAsset, link, videoAsset } from '@/data/sanity/fragments'
import cmsSettings from '../cmsSettings'

export const fields = `
  _type,
  _id,
  ${imageAsset.fragment('eyebrowImage')},
  title[]{
    ...,
    markDefs[]{
      ...,
      _type == "link" => {
        ${link.fields}
      },
    },
    _type == "link" => {
      ${link.fields}
    },
  }, 
  subtitle,
  ${cmsSettings()},
  "sectionBackground": 'black',
  ${link.fragment('primaryCta')},
  ${link.fragment('secondaryCta')},
  mediaType,
  overlayOpacity,
  overlayDuration,
  ${imageAsset.fragment('image')},
  ${videoAsset.fragment('videoLoopDesktop')},
  ${videoAsset.fragment('videoLoopMobile')}
`

export const fragment = (name = 'homepageHeroLNY') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
