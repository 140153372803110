export const setCookie = ({ name, value, maxAge }: { name: string; value: string; maxAge: number }) => {
  document.cookie = `${name}=${value}; path=/; max-age=${maxAge};`
}

export const getCookie = (name: string) => {
  if (typeof document === 'undefined') {
    return null
  }

  const match = document.cookie.match(new RegExp(`(^| )${  name  }=([^;]+)`))
  if (match) return match[2]
  return null
}

export const deleteCookie = (name: string) => {
  setCookie({ name, value: '', maxAge: 0 })
}
