import link from './link'

export const fields = `
  headingContent[] {
    _key,
    _type,
    children[] {
      ...
    },
    style,
    listItem,
    level
  },
  "desktopTitleSize": coalesce(desktopTitleSize, 'large'),
  "mobileTitleSize": coalesce(mobileTitleSize, 'large'),
  "hasNarrowContainer": coalesce(hasNarrowContainer, false),
  "primaryCta": primaryCta[]{
    ${link.fields}
  }[0],
  "secondaryCta": secondaryCta[]{
    ${link.fields}
  }[0],
  hasNewsletterSignup,
  alignItems
`

export const fragment = (name: string) => `${name}{
	${fields}
}`

const exports = { fields, fragment }

export default exports
