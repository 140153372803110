// Analytics
import { sendEvent, GTM_ID } from './analytics'

// Animation
import { lerp, wait } from './animation'

// Device detection
import { deviceInfo, getDeviceInfo } from './device'

// DOM
import {
  calculateVhSizing,
  getCssVar,
  setBodyCursor,
  waitForWebfonts,
} from './dom'

// Format
import {
  buildIdFromText,
  bytesToMb,
  formatBytes,
  formatMilitaryTime,
  formatMilitaryTimeWithoutMinutes,
  getFormattedDateString,
  getRestaurantAddressString,
  replaceTextStringWithVars,
} from './format'

// Image
import {
  getImageUrl,
  proxyAssetCdn,
  simpleImagesPreload,
} from './images'

// Restaurant
import {
  getIfRestaurantIsOpen,
  getRestaurantClosingHour,
  getMergedHoursWithHolidayHours,
  getMergedTimeRangeHoursWithTimeRangeHolidayHours,
  getLocationListItemId,
  getPrivateDiningId,
  getIfRestaurantIsOpenNewFormat,
  getRestaurantClosingHourNewFormat,
} from './restaurant'

// Sanity CMS
import {
  formatPageSections,
  getSections,
  formatSiteSettingsResponse,
  mergeSiteSettings,
} from './sanity'

// SEO
import {
  formatMetadata,
  getFormattedMetadata,
  generateMetadataReturn,
  generateOrgStructuredDataSchema,
} from './seo'

// Storage
import {
  deleteCookie,
  getCookie,
  setCookie,
} from './storage'

// String
import {
  createRandomString,
  CHARS,
  ID_LENGTH,
} from './strings'

// URL
import {
  getPagePathBySlug,
  getMenuItemPathBySlug,
  getLocationPathBySlug,
  getBlogPostPathBySlug,
  getMenuItemListingGroupId,
  getLocationSlug,
  getUrlFromPageData,
  getMenuPagePreviewKey,
} from './urls'

// Re-export all grouped by category
export {
  // Analytics
  sendEvent,
  GTM_ID,

  // Animation
  lerp,
  wait,

  // Device
  deviceInfo,
  getDeviceInfo,

  // DOM
  calculateVhSizing,
  getCssVar,
  setBodyCursor,
  waitForWebfonts,

  // Format
  buildIdFromText,
  bytesToMb,
  formatBytes,
  formatMilitaryTime,
  formatMilitaryTimeWithoutMinutes,
  getFormattedDateString,
  getRestaurantAddressString,
  replaceTextStringWithVars,

  // Image
  getImageUrl,
  proxyAssetCdn,
  simpleImagesPreload,

  // Restaurant
  getIfRestaurantIsOpen,
  getIfRestaurantIsOpenNewFormat,
  getRestaurantClosingHour,
  getRestaurantClosingHourNewFormat,
  getMergedHoursWithHolidayHours,
  getMergedTimeRangeHoursWithTimeRangeHolidayHours,
  getLocationListItemId,
  getPrivateDiningId,

  // Sanity
  formatPageSections,
  getSections,
  formatSiteSettingsResponse,
  mergeSiteSettings,

  // SEO
  formatMetadata,
  getFormattedMetadata,
  generateMetadataReturn,
  generateOrgStructuredDataSchema,

  // Storage
  deleteCookie,
  getCookie,
  setCookie,

  // String
  createRandomString,
  CHARS,
  ID_LENGTH,

  // URL
  getPagePathBySlug,
  getMenuItemPathBySlug,
  getLocationPathBySlug,
  getBlogPostPathBySlug,
  getMenuItemListingGroupId,
  getLocationSlug,
  getUrlFromPageData,
  getMenuPagePreviewKey,
}

