import { DOC_TYPES } from '@/data'
import { getBlogPostPathBySlug, getLocationPathBySlug, getMenuItemPathBySlug, getPagePathBySlug } from '@/utils/urls/paths'

export const getMenuItemListingGroupId = (slug: string) => `id_${slug}`

export const getLocationSlug = (location: string) => `home-${location}`

export const getUrlFromPageData = (pageType: string, slug: string): string => {
  let url = ''

  switch (pageType) {
    case DOC_TYPES.PAGE:
      url = getPagePathBySlug(slug)
      break
    case DOC_TYPES.MENU_ITEM:
      url = getMenuItemPathBySlug(slug)
      break
    case DOC_TYPES.LOCATION:
      url = getLocationPathBySlug(slug)
      break
    case DOC_TYPES.BLOG_POST:
      url = getBlogPostPathBySlug(slug)
      break
    default:
      break
  }

  return url
}

export const getMenuPagePreviewKey = (type: string, slug: string) => `${type}_${slug}`
