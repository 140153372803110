import { ResolvedMetadata } from 'next'
import { ResolvedOpenGraph } from 'next/dist/lib/metadata/types/opengraph-types'

import {
  DEFAULT_LANGUAGE,
  DOC_TYPES,
} from '@/data'
import { getPage } from '@/data/sanity'

import { geti18nText } from '@/hooks/use-i18n'
import { getImageUrl } from '@/utils/images/transformations'

export const formatMetadata = (
  metadata: SanityMetadata | null | undefined,
  pageData?: SanityPage | null,
  language: string = DEFAULT_LANGUAGE,
) => {
  if (!metadata || !metadata.image || !metadata.favicon) return {}

  // Title
  let pageTitle = metadata.title || pageData?.title
  if (pageData?._type === DOC_TYPES.LOCATION && !metadata.title) {
    pageTitle = geti18nText(language, 'titleAndRestaurantText', { title: pageData.title }) as string
  }

  if (!metadata.defaultSiteTitle) {
    metadata.defaultSiteTitle = '404'
  }

  let title = `${pageTitle} | ${metadata.defaultSiteTitle}`
  if (pageTitle === metadata.defaultSiteTitle) {
    title = metadata.defaultSiteTitle || ''
  }

  // Description
  let description = metadata.defaultSiteDescription
  if (metadata.description) {
    description = metadata.description
  }
  if (pageData?._type === 'menuItem' && !metadata.description && pageData?.menuItemData?.description) {
    description = pageData.menuItemData?.description
  }

  // Keywords
  let keywords = metadata.defaultSiteKeywords
  if (metadata.keywords) {
    keywords = metadata.keywords
  }

  const data: any = {
    title,
    robots: {
      index: metadata.allowCrawlers,
      follow: metadata.allowCrawlers,
      nocache: !metadata.allowCrawlers,
      googleBot: {
        index: metadata.allowCrawlers,
        follow: metadata.allowCrawlers,
        noimageindex: !metadata.allowCrawlers,
      },
    },
    metadataBase: new URL(process.env.NEXT_PUBLIC_SITE_URL || ''),
    description,
    keywords: keywords?.split(','),
    openGraph: {
      title,
      description,
      siteName: metadata.defaultSiteTitle,
      images: [
        {
          url: getImageUrl(metadata.image, { width: 1200, height: 630, fit: 'crop' }) || '',
          width: 1200,
          height: 630,
          alt: metadata.title,
        },
      ],
      type: 'website',
    },
    twitter: {
      card: 'summary_large_image',
      title,
      description,
      images: [
        {
          url: getImageUrl(metadata.image, { width: 1024, height: 512, fit: 'crop' }) || '',
          width: 1024,
          height: 512,
          alt: metadata.title,
        },
      ],
    },
  }

  return data
}

export const getFormattedMetadata = async (slug: string, docType: string, isDraftMode: boolean, language: string) => {
  const data = await getPage(slug, docType, isDraftMode, language)
  const metadata = data?.metadata

  return formatMetadata(metadata, data as SanityPage, language)
}

export const generateMetadataReturn = ({
  title,
  description,
  shareImageUrl,
  parentData,
  allowCrawlers = true,
  themeColor = '#ffffff',
}: {
  title?: string
  description?: string
  shareImageUrl?: string
  allowCrawlers?: boolean
  parentData?: ResolvedMetadata
  themeColor?: string
}) => {
  const shareImagesParent = parentData?.openGraph?.images
  const imageChild = shareImagesParent?.length ? (shareImagesParent[0] as ResolvedOpenGraph) : null

  return {
    title: title || parentData?.title?.absolute,
    description: description || parentData?.description,
    metadataBase: new URL(process.env.NEXT_PUBLIC_SITE_URL || ''),
    themeColor,
    manifest: '/manifest.webmanifest',
    openGraph: {
      title: title || parentData?.title?.absolute,
      description: description || parentData?.description,
      images: [`${shareImageUrl ? shareImageUrl : imageChild ? imageChild.url : shareImageUrl}`],
    },
    robots: {
      index: allowCrawlers,
      follow: allowCrawlers,
      nocache: !allowCrawlers,
      googleBot: {
        index: allowCrawlers,
        follow: allowCrawlers,
        noimageindex: !allowCrawlers,
      },
    },
  }
}
