export const getRestaurantAddressString = ({
  streetAddress,
  city,
  state,
  postalCode,
}: {
  streetAddress?: string
  city?: string
  state?: string
  postalCode?: string
}) => {
  let str = ''

  if (!streetAddress || !city || !state || !postalCode) {
    return str
  }

  if (streetAddress) {
    str += streetAddress
  }
  if (city) {
    str += `, ${city}`
  }
  if (state) {
    str += `, ${state}`
  }
  if (postalCode) {
    str += ` ${postalCode}`
  }

  return str
}

export const replaceTextStringWithVars = (textString: string, variables: any) => {
  Object.keys(variables).forEach(variable => {
    textString = textString.replaceAll(`#{${variable}}`, variables[variable])
  })
  return textString
}
