export const getDeviceInfo = () => {
  const isBrowser = typeof window !== 'undefined'

  /* eslint-disable */
  const detect = {
    device: {},
    browser: {},
    os: {},
    bots: {},
    isTouchDevice: isBrowser && ('ontouchstart' in window || navigator.maxTouchPoints > 0),
  } as {
    device: any
    browser: any
    os: any
    bots: any
    isTouchDevice: boolean
  }

  if (isBrowser) {
    detect.device = require('@jam3/detect').device
    detect.browser = require('@jam3/detect').browser
    detect.os = require('@jam3/detect').os
    detect.bots = require('@jam3/detect').bots
  }

  /* eslint-disable */

  return detect
}

export const deviceInfo = getDeviceInfo()
