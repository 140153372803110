import { proxyAssetCdn } from '@/utils/images/cdn'

export const getImageUrl = (
  image: SanityImage,
  {
    width = null,
    height = null,
    fit = null,
    quality = 80,
    invert = false,
    fm = 'webp',
    sat,
    rect = null,
  }: SanityImageOptions,
) => {
  if (!image?.asset?.url) {
    console.warn('No image.asset.url in image object supplied. ', image)
    return null
  }

  let url = image.asset.url

  const params = []
  if (width) params.push(`w=${width}`)
  if (height) params.push(`h=${height}`)
  if (quality) params.push(`q=${quality}`)
  if (invert) params.push(`invert=${invert}`)
  if (fm) params.push(`fm=${fm}`)
  if (sat) params.push(`sat=${sat}`)
  if (rect) params.push(`rect=${rect}`)
  if (fit) params.push(`fit=${fit}`)

  url = `${url}?${params.join('&')}`
  const newUrl = proxyAssetCdn(url)
  return newUrl as string
}
